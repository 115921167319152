<template>
  <div class="menu-body" v-if="
    $oidc.isAuthenticated &&
    $oidc.userProfile &&
    $oidc.userProfile.roles &&
    $oidc.userProfile.roles.length > 0
  ">
    <Loader v-if="showLoader" />
    <Breadcrumb />
    <div class="asmt-con">
      <div class="principle-dd margin-top-1em">
        <div class="single-select">
          <label for="pillarSelect" class="form-label page-heading-dropdown lable-text">
            Pillar
          </label>
          <select class="
              form-control
              page-heading-dropdown-content
              principle-select
              cust-st-icon
            " id="pillarSelect" v-model="pillarId" disabled @change="pillarDetails()">
            <option disabled value="-1">Select Pillar</option>
            <option v-for="each in pillarList" :key="each.pillarId" :value="each.pillarId">
              {{ each.pillarName }}
            </option>
          </select>
        </div>
        <div class="single-select">
          <label for="principleSelect" class="form-label page-heading-dropdown lable-text">
            Principle
          </label>
          <select class="
              form-control
              page-heading-dropdown-content
              principle-select
              cust-st-icon
            " id="principleSelect" v-model="principleId" @change="principleDetails()" disabled>
            <option disabled value="-1">Select Principle</option>
            <option v-for="(each, index) in principleList" :key="each.principleId"
              :disabled="each.active === 'Y' ? false : true" :value="each.principleId">
              {{ index + 1 }}. {{ each.principleName }}
            </option>
          </select>
        </div>
      </div>

      <div class="level-con">
        <div class="level-border">
          <b-form-radio-group id="radio-group-1" v-model="targetLevel" name="radio-options"
            style="display: inline-flex; width: 100%">
            <div class="page-heading-tab level-info level-br live-level" style="border-radius: 4px 0 0 4px" id="Level0">
              <label class="container">
                Level 0
                <input type="radio" class="cursor-pointer" name="some-radios" value="0" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level1">

              <label class="container">
                Level 1
                <input type="radio" class="cursor-pointer" name="some-radios" value="1" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level2">

              <label class="container">
                Level 2
                <input type="radio" class="cursor-pointer" name="some-radios" value="2" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level3">

              <label class="container">
                Level 3
                <input type="radio" class="cursor-pointer" name="some-radios" value="3" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info level-br live-level" id="Level4">

              <label class="container">
                Level 4
                <input type="radio" class="cursor-pointer" name="some-radios" value="4" style="margin-right: 1rem" />

              </label>
            </div>
            <div class="page-heading-tab level-info live-level" id="Level5" style="border-radius: 0 4px 4px 0">

              <label class="container">
                Level 5
                <input type="radio" class="cursor-pointer" name="some-radios" value="5" style="margin-right: 1rem" />

              </label>
            </div>
          </b-form-radio-group>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name">
          {{ principleNumber + "." + targetLevel + "." + principleName }}
        </div>
        <div class="page-content-regular prp-name">
          <pre class="page-content-regular">{{ this.practiceDescription }}</pre>
        </div>
        <div class="module-title prp-name margin-top-1em">Guidelines</div>
        <div class="behavoir-des page-content-regular" id="guidelineDescription"></div>
      </div>
      <div class="attch-con">
        <div class="module-title prp-name margin-top-1em">Attachments</div>
        <div class="attch-row">
          <div class="col-6 attch-col page-content-regular" v-for="eachAttachment in attachmentUrls"
            :key="eachAttachment.id">
            <a @click="downloadItem(eachAttachment)" :download="eachAttachment">
              <span>
                {{ attachmentNameCorrection(eachAttachment) }}
              </span>
              <a class="li-doc-class" @click="downloadItem(eachAttachment)" :download="eachAttachment">
                <img alt="more" style="margin-left: 1rem" src="../../../assets/download 2.svg" />
              </a>
            </a>
          </div>
        </div>
      </div>
      <!-- ------------here comes the changes------------- -->
      <div class="prp-info gray-box-outer">
        <div class="gray-box-title prp-name">Proposed Recommendations</div>
        <div class="gray-box-center">
          <div class="gray-box-left">
            <img alt="more" class="attch-icon" src="../../../assets/arrow-left.svg" v-if="showLeft"
              @click="leftShift()" />
            <img alt="more" class="attch-icon" src="../../../assets/arrow-left-disabled.svg" v-else />
          </div>
          <div class="gray-box-inner">
            <div class="gray-box-inner-row1" :class="{ 'width-for-three': showPlantName }">
              <div class="row1-items">
                <label for="principleSelect" class="
                    form-label
                    page-heading-dropdown
                    lable-text
                    gray-box-label
                  ">
                  Change Type
                </label>
                <b-input class="
                    form-control
                    page-heading-dropdown-content
                    row1-items-input
                  " id="changeType" v-model="recommendationsList[index].changeType" disabled>
                </b-input>
              </div>
              <div class="row1-items">
                <label for="principleSelect" class="
                    form-label
                    page-heading-dropdown
                    lable-text
                    gray-box-label
                  ">
                  Submitted By
                </label>
                <b-input class="
                    form-control
                    page-heading-dropdown-content
                    row1-items-input
                  " id="submitBy" v-model="recommendationsList[index].submittedBy" disabled>
                </b-input>
              </div>
              <div class="row1-items" v-if="showPlantName && recommendationsList[index].plant">
                <label for="principleSelect" class="
                    form-label
                    page-heading-dropdown
                    lable-text
                    gray-box-label
                  ">
                  Plant
                </label>
                <b-input class="
                    form-control
                    page-heading-dropdown-content
                    row1-items-input
                  " id="plant" v-model="recommendationsList[index].plant" disabled>
                </b-input>
              </div>
              <div class="row1-items" v-else-if="showPlantName && recommendationsList[index].region">
                <label for="principleSelect" class="
                    form-label
                    page-heading-dropdown
                    lable-text
                    gray-box-label
                  ">
                  Region
                </label>
                <b-input class="
                    form-control
                    page-heading-dropdown-content
                    row1-items-input
                  " id="plant" v-model="recommendationsList[index].region" disabled>
                </b-input>
              </div>
            </div>
            <div class="gray-box-inner-row2">
              <label for="propsProcess" class="
                  form-label
                  page-heading-dropdown
                  lable-text
                  gray-box-label
                ">
                Proposed Practice
              </label>
              <textarea class="page-content-regular action-desc disabled" id="propsProcess" name="propsProcess" rows="4"
                cols="105" disabled ref="actionDetailTA"
                v-model="recommendationsList[index].proposedPractice"></textarea>
            </div>
            <div class="gray-box-inner-row2">
              <label for="reasonChange" class="
                  form-label
                  page-heading-dropdown
                  lable-text
                  gray-box-label
                ">
                Reason for Change / Comments
              </label>
              <textarea class="page-content-regular action-desc disabled" id="reasonChange" name="reasonChange" rows="4"
                cols="105" disabled ref="actionDetailTA"
                v-model="recommendationsList[index].reasonForChange"></textarea>
            </div>
          </div>
          <div class="gray-box-right">
            <img alt="more" class="attch-icon" src="../../../assets/arrow-right.svg" v-if="showRight"
              @click="rightShift()" />
            <img alt="more" class="attch-icon" src="../../../assets/arrow-right-disabled.svg" v-else />
          </div>
        </div>
      </div>
      <div class="prp-info">
        <div class="module-title prp-name margin-top-1em">
          Perform Action Details
        </div>
        <div class="select-with-button">
          <div style="width: 40%">
            <label for="principleSelect" class="form-label page-heading-dropdown lable-text">
              Change Type <span class="mark-red">*</span>
            </label>
            <select class="
                form-control
                page-heading-dropdown-content
                change-type-select
                cust-st-icon
              " id="new-change-type" v-model="newChangeType" @change="changeTypeChange()">
              <option v-for="eachoption in changeTypeList" :key="eachoption.typeId" :value="eachoption.typeId">
                {{ eachoption.typeDescription }}
              </option>
            </select>
          </div>
          <div v-if="userIsGlobal">
            <b-button pill class="primar-btn clone-btn" size="lg" @click="onCloneAll()">
              Clone All
            </b-button>
            <b-button pill class="primar-btn clone-btn" size="lg" @click="onCloneCurrent()">
              Clone Current
            </b-button>
          </div>
        </div>
        <div style="margin-top: 1rem" class="styled-input" v-if="userIsGlobal">
          <textarea class="page-content-regular action-desc" id="newProposedProcess" name="newProposedProcess" rows="4"
            cols="105" ref="newProposedProcess" v-model="newProposedProcess"></textarea>
          <label :class="{ 'comment-lable': newProposedProcess }" for="principleSelect"
            class="form-label page-heading-dropdown lable-text">
            Proposed Practice <span class="mark-red">*</span>
          </label>
        </div>
        <div style="margin-top: 1rem" class="styled-input" v-else>
          <textarea class="page-content-regular action-desc" id="newPillarDesc" name="newPillarDesc" rows="4" cols="105"
            ref="newPillarDesc" v-model="newPillarDesc"></textarea>
          <label :class="{ 'comment-lable': newPillarDesc }" for="principleSelect"
            class="form-label page-heading-dropdown lable-text">
            Practice Description <span class="mark-red">*</span>
          </label>
        </div>
        <div style="margin-top: 1rem" class="styled-input" v-if="userIsGlobal">
          <label :class="{ 'comment-lable': newReasonChange }" for="newReasonChange"
            class="form-label page-heading-dropdown lable-text">
            Reason for Change / Comments <span class="mark-red">*</span>
          </label>
          <textarea class="page-content-regular action-desc" id="newReasonChange" name="newReasonChange" rows="4"
            cols="105" ref="newReasonChange" v-model="newReasonChange"></textarea>
        </div>
        <div style="margin-top: 1rem" class="styled-input" v-else>
          <label :class="{ 'comment-lable': newGuideline }" for="newGuideline"
            class="form-label page-heading-dropdown lable-text">
            Guidelines <span class="mark-red">*</span>
          </label>
          <textarea class="page-content-regular action-desc" id="newGuideline" name="newGuideline" rows="4" cols="105"
            ref="newGuideline" v-model="newGuideline"></textarea>
        </div>
      </div>
      <div class="add-action-footer-info">
        <b-button class="discard-btn" size="lg" @click="discardRequest()">
          Discard
        </b-button>
        <b-button v-b-modal.messagePopup pill class="primar-btn submit-btn" size="lg" @click="onSubmit()">
          Submit
        </b-button>

      </div>
    </div>
    <MessagePopup :routToBack="true" :message="errorMsg" :show="showPopup" :headText="'Error'" />
  </div>
</template>
<script>
import Breadcrumb from "../../../components/common/Breadcrumb.vue";
import Loader from "../../../components/common/Loader.vue";
import ApiCalls from "../../../common/api/apiCalls";
import Api from "../../../common/constants/api";
import MessagePopup from "@/components/common/MessagePopup.vue";
import Role from "../../../common/constants/roles";
import axios from 'axios';
export default {
  name: "Governance",
  data() {
    return {
      roles: Role,
      showLoader: false,
      pillarId: null,
      principleId: null,
      plantId: null,
      showPlantName: false,
      practiceDescription: "-",
      attachmentUrls: [],
      recommendationsList: [
        {
          changeType: "",
          changeId: null,
          requestId: null,
          reasonForChange: "",
          proposedPractice: "",
          requesterId: null,
          submittedBy: "",
          plant: null,
          region: null,
        },
      ],
      newChangeType: "",
      newProposedProcess: "",
      newReasonChange: "",
      targetLevel: 0,
      index: 0,
      practiceDetailsData: [],
      pillarList: [],
      principleList: [],
      changeTypeList: [],
      previousRequestId: [],
      errorMsg: "",
      showPopup: false,
      principleName: "-",
      principleNumber: 1,
      requestIdsArray: [],
      userIsGlobal: false,
      newGuideline: "",
      newPillarDesc: "",
    };
  },
  computed: {
    showLeft() {
      return this.index > 0 ? true : false;
    },
    showRight() {
      return this.index < this.recommendationsList.length - 1 ? true : false;
    },
  },
  components: {
    Breadcrumb,
    Loader,
    Api,
    ApiCalls,
    MessagePopup,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 2,
      subMenu: 4,
    });
    this.$store.dispatch("breadcrumb", {
      title: "Perform Action Details",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Governance / ", primary: true, redirect: "/governance" },
        { name: "Perform Action Details", primary: false },
      ],
    });
  },
  created() {
    ///:level/:reqIds    this.$oidc.userProfile.roles = this.roles.GLOBALPILLARLEADER;
    this.plantId = Number(this.$route.params.plantId);
    this.pillarId = Number(this.$route.params.pillarId);
    this.principleId = Number(this.$route.params.principleId);
    this.targetLevel = Number(this.$route.params.level);
    this.requestIdsArray = JSON.parse(this.$route.params.reqIds);
    this.showLoader = true;
    this.getPillarList(this.plantId);
    this.getChangeType();
    this.newChangeType = "-1";
    this.getRecommendations();

    if (
      this.$oidc.userProfile.roles.includes(this.roles.REGIONALPOLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.REGIONALPILLARLEADER) ||
      this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER)
    )
      this.showPlantName = true;
    else this.showPlantName = false;
    if (this.$oidc.userProfile.roles.includes(this.roles.GLOBALPILLARLEADER))
      this.userIsGlobal = false;
    else this.userIsGlobal = true;
  },
  methods: {
    async downloadItem(obj) {
      const url = JSON.parse(JSON.stringify(obj));
      const label = JSON.parse(JSON.stringify(attachmentNameCorrection(obj)));
      const response = await axios.get(url, { responseType: "blob" });
      // apiCalls.httpCallDetails(url, "get").then((response) => {
      console.log(response);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
      // })

    },
    async getPillarList(plantId) {
      let url = Api.PILLARLIST + plantId;
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        console.log("PillarList ", data);
        this.pillarList = data.data;
        this.pillarId = this.pillarId ? this.pillarId : data.data[0].pillarId;
        this.getPrincipleList(this.pillarId);
      });
    },
    async getPrincipleList(pillarId) {
      ApiCalls.httpCallDetails(Api.PRINCIPLELIST + pillarId, "get").then(
        (dataJSON) => {
          this.principleList = dataJSON.data;
          this.principleId = this.principleId
            ? this.principleId
            : dataJSON.data[0].principleId;
          this.getAssessmentDetails();
          let index = this.principleList.findIndex(
            (val) => val.principleId == this.principleId
          );
          this.principleName = dataJSON.data[index].principleName;
          this.principleNumber = dataJSON.data[index].principleNumber;
        }
      );
    },
    async getAssessmentDetails() {
      ApiCalls.httpCallDetails(
        Api.PRACTISEDETAILS + this.principleId + "/" + this.plantId,
        "get"
      ).then((dataJSON) => {
        console.log("PRACTISEDETAILS", dataJSON);
        if (dataJSON.success) {
          this.showLoader = false;
          this.practiceDetailsData = dataJSON.data.practiceList;
          this.handleRadioChange(this.targetLevel);
        }
      });
    },
    getChangeType() {
      ApiCalls.httpCallDetails(Api.GETCHANGETYPEDROPDOWN, "get").then(
        (data) => {
          this.changeTypeList = data.data;
        }
      );
    },
    attachmentNameCorrection(eachAttachment) {
      let name = eachAttachment
        .split("/")
      [eachAttachment.split("/").length - 1].split("_")[0];
      console.log(name);
      let type = eachAttachment
        .split("/")
      [eachAttachment.split("/").length - 1].split(".")[
        eachAttachment
          .split("/")
        [eachAttachment.split("/").length - 1].split(".").length - 1
      ];
      return decodeURIComponent(name + "." + type);
    },
    getRecommendations() {
      //
      let req = {
        requestIds: this.requestIdsArray,
      };

      console.log(req);
      this.showLoader = true;

      ApiCalls.httpCallDetails(Api.GETRECOMMENDATIONS, "post", req).then(
        (dataJSON) => {
          console.log("res", dataJSON);
          this.recommendationsList = dataJSON.data;
          this.showLoader = false;
        }
      );
    },
    pillarDetails() {
      this.showLoader = true;
      this.principleId = null;
      this.getPrincipleList(this.pillarId);
    },
    principleDetails() {
      this.showLoader = true;
      let dropdown = document.querySelector("#principleSelect");
      this.principleName = dropdown.selectedOptions[0].text;
      this.getAssessmentDetails();
    },
    handleRadioChange(id) {

      for (let i = 0; i <= 5; i++) {
        let radio = document.getElementById("Level" + i);
        radio.classList.remove("active-level");
      }
      let element = document.getElementById("guidelineDescription");
      element.innerHTML =
        this.practiceDetailsData[parseInt(id)] &&
          this.practiceDetailsData[parseInt(id)].guideline
          ? this.practiceDetailsData[parseInt(id)].guideline
          : "";
      this.practiceDescription =
        this.practiceDetailsData[parseInt(id)] &&
          this.practiceDetailsData[parseInt(id)].practiceDescription
          ? this.practiceDetailsData[parseInt(id)].practiceDescription
          : "";
      let radioElement = document.getElementById("Level" + id);
      radioElement.classList.add("active-level");
      this.practiceId =
        this.practiceDetailsData[parseInt(id)] &&
          this.practiceDetailsData[parseInt(id)].practiceId
          ? this.practiceDetailsData[parseInt(id)].practiceId
          : "";
      this.attachmentUrls =
        this.practiceDetailsData[parseInt(id)].attachmentUrls;
    },
    leftShift() {
      this.index -= 1;
    },
    rightShift() {
      this.index += 1;
    },
    changeTypeChange() { },
    onCloneAll() {
      this.recommendationsList.forEach((element) => {
        this.newProposedProcess += element.proposedPractice + " \n";
        this.newReasonChange += element.reasonForChange + " \n";
        this.previousRequestId.push(element.requestId);
      });
    },
    onCloneCurrent() {
      if (this.newProposedProcess) {
        this.newProposedProcess += "\n";
        this.newReasonChange += "\n";
      }
      this.newProposedProcess +=
        this.recommendationsList[this.index].proposedPractice + " \n";
      this.newReasonChange +=
        this.recommendationsList[this.index].reasonForChange + " \n";
      this.previousRequestId.push(
        this.recommendationsList[this.index].requestId
      );
    },
    discardRequest() {
      this.$router.push("/governance");
    },
    onSubmit() {
      this.showPopup = false;
      if (this.userIsGlobal) {
        this.simpleSubmit();
      } else {
        this.forGlobal();
      }
    },
    forGlobal() {
      if (Number(this.newChangeType) === -1) {
        console.log(this.newChangeType);
        this.showPopup = true;
        this.errorMsg = "Please select a change type.";
        return;
      }
      if (!this.newPillarDesc.length) {
        console.log("this.newPillarDesc");
        this.showPopup = true;
        this.errorMsg = "Please add some text in Pillar Description.";
        return;
      }
      if (!this.newGuideline.length) {
        console.log("this.newGuideline");
        this.showPopup = true;
        this.errorMsg = "Please add some text in Guidelines.";
        return;
      }

      this.previousRequestId = !this.previousRequestId.length
        ? this.requestIdsArray
        : this.previousRequestId;

      let req = {
        pillarId: Number(this.pillarId),
        principleId: Number(this.principleId),
        levelId: Number(this.targetLevel),
        practiceId: Number(this.practiceId),
        changeId: Number(this.newChangeType),
        proposedPractice: this.newPillarDesc,
        reason: "",
        guidelines: this.newGuideline,
        previousRequestId: this.previousRequestId,
      };

      console.log("for global", req);
      this.showLoader = true;

      ApiCalls.httpCallDetails(Api.ADDCONSOLIDATEDREQUEST, "post", req).then(
        (dataJSON) => {
          console.log("res", dataJSON);
          this.showLoader = false;
          if (dataJSON.success) {
            this.$router.push("/governance");
          }
        }
      );
    },
    simpleSubmit() {
      if (Number(this.newChangeType) === -1) {
        console.log(this.newChangeType);
        this.showPopup = true;
        this.errorMsg = "Please select a change type.";
        return;
      }
      if (!this.newProposedProcess.length) {
        console.log("this.newProposedProcess");
        this.showPopup = true;
        this.errorMsg = "Please add some text in proposed process.";
        return;
      }
      if (!this.newReasonChange.length) {
        console.log("this.newReasonChange");
        this.showPopup = true;
        this.errorMsg = "Please add a valid reason for change.";
        return;
      }

      this.previousRequestId = this.requestIdsArray;

      let req = {
        pillarId: Number(this.pillarId),
        principleId: Number(this.principleId),
        levelId: Number(this.targetLevel),
        practiceId: Number(this.practiceId),
        changeId: Number(this.newChangeType),
        proposedPractice: this.newProposedProcess,
        reason: this.newReasonChange,
        guidelines: null,
        previousRequestId: this.previousRequestId,
      };

      console.log(req);
      this.showLoader = true;

      ApiCalls.httpCallDetails(Api.ADDCONSOLIDATEDREQUEST, "post", req).then(
        (dataJSON) => {
          console.log("res", dataJSON);
          this.showLoader = false;
          if (dataJSON.success) {
            this.$router.push("/governance");
          }
        }
      );
    },
  },
};
</script>
<style scoped>
.uploaded-items-li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.li-doc-class {
  margin-bottom: 8px;
  color: #274ba7;
  font-family: "BarlowR", sans-serif;
  list-style: none;
  line-height: 1.9166666666666667rem;
  font-size: 1.1666666666666667rem;
}

.asmt-con {
  background: #fff;
  border-radius: 6px;
  margin-top: 1em;
  padding-bottom: 1em;
}

.principle-dd {
  width: 80%;
  padding: 2rem;
  display: flex;
  padding-bottom: 0;
  align-items: center;
}

.single-select {
  width: 45%;
  margin-right: 3rem;
}

.margin-top-1em {
  margin-top: 1em;
}

.lable-text {
  z-index: 2;
  color: #313131;
}

.principle-select {
  height: 4.4rem;
  border: 1px solid #d5d2d2;
  background-color: #f9f9f9 !important;
}

.level-con {
  padding: 1em;
  /* margin-top: 1em; */
  padding-left: 2em;
  padding-right: 2em;
  cursor: pointer;
}

.level-info {
  width: 17%;
  text-align: center;
  height: 64px;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
}

.level-border {
  display: flex;
  border: 1px solid #dedbdb;
  border-radius: 6px;
}

.live-level {
  background: #eff3ff;
  opacity: 0.5;
}

.action-desc {
  border: #313131 1px solid;
  border-radius: 4px;
  padding: 1rem;
  width: 100%;
  color: #313131;
  resize: none;
}

.disabled {
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer;
}

.level-br {
  border-right: 1px solid #dedbdb;
}




.active-level {
  background: #6577ac;
  color: #fff;
  opacity: 1 !important;
}

.active-level:after {
  content: "";
  position: relative;
  top: 58%;
  left: -45%;
  /* margin-left: -50px; */
  width: 0;
  height: 0;
  border-top: solid 10px #6577ac;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
}

.prp-info {
  padding-left: 2em;
  padding-right: 2em;
}

.prp-name {
  margin-top: 3px;
}

.module-title {
  font-size: 1.333rem;
  font-family: "BarlowM", sans-serif;
  margin-bottom: 9px;
}

.gray-box-outer {
  background-color: #ecedf4;
  margin: 0 2rem 1rem 2rem;
  padding: 1rem 2rem;
  height: 33rem;
  border-radius: 6px;
}

.gray-box-left {
  flex: 0;
}

.gray-box-inner {
  flex: 1;
}

.gray-box-right {
  flex: 0;
}

.gray-box-inner-row1 {
  padding: 1rem 2rem;
  width: 65%;
  display: flex;
}

.row1-items {
  margin-right: 1rem;
  width: 23rem;
}

.row1-items-input {
  height: 4.4rem;
  border-color: #313131;
}

.width-for-three {
  width: 85% !important;
}

.gray-box-inner-row2 {
  padding: 0.5rem 2rem;
}

.gray-box-center {
  display: flex;
  align-items: center;
}

.gray-box-title {
  font-size: 1.5rem;
  font-family: "BarlowM", sans-serif;
}

.gray-box-label {
  background: #ecedf4;
}

.select-with-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clone-btn {
  color: #274ba7;
  height: 3.3rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  background: transparent;
  border: 1px solid #274ba7;
  margin-left: 1rem;
  width: 9.8rem;
}

.clone-btn:focus {
  background-color: transparent;
  color: #274ba7;
}

.mark-red {
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  color: red;
  vertical-align: sub;
}

.attch-col {
  border-radius: 6px;
  padding: 5px 0 !important;
  /* padding-bottom: 10px !important; */
  color: #274ba7;
}

.container {
  position: relative;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide the default radio button */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.attch-icon {
  float: right;
}

.add-action-footer-line {
  width: 98%;
  margin: 12px 0px;
  margin-left: 1%;
  border-top: 1px solid #707070b6;
}

.add-action-footer-info {
  padding-left: 2em !important;
  padding-right: 2em !important;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
}

.change-type-select {
  height: 4.4rem;
  border-color: #313131;
}

.discard-btn {
  color: #274ba7;
  background: transparent;
  border: transparent;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  margin-right: 2rem;
}

.discard-btn:active {
  color: #274ba7 !important;
  background: transparent !important;
  border: transparent !important;
  font-family: "BarlowM", sans-serif !important;
  font-size: 1.166rem !important;
  margin-right: 2rem !important;
}

.submit-btn {
  height: 3.3rem;
  width: 7.9rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.attch-con {
  padding-left: 2em;
  padding-right: 2em;
}

/* ---- ----------- */

@media only screen and (max-width: 1100px) {
  .active-level:after {
    left: -27% !important;
  }
}

@media only screen and (max-width: 875px) {
  .active-level:after {
    left: -30% !important;
  }
}

@media only screen and (max-width: 575px) {
  .level-con {
    overflow-x: auto;
  }

  .level-info {
    padding: 1em;
    width: 41%;
  }

  .principle-dd {
    width: 50%;
  }

  .active-level:after {
    left: -40% !important;
  }
}

@media only screen and (min-width: 768px) {
  active-level:after {
    top: 71.5%;
    left: 61%;
    margin-left: -45px;
  }

  .review-type-col {
    max-width: 30.333333% !important;
  }

  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 768px) {
  .review-rox .col-6 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .asmt-info {
    display: block !important;
  }

  .asmt-name {
    width: 100% !important;
  }

  .progress-con {
    width: 100% !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (max-width: 1342px) {
  .pm-rw-con {
    margin-right: 35px !important;
  }
}

@media only screen and (min-width: 992px) {
  .review-rox .col-6 {
    max-width: 47% !important;
  }
}

@media only screen and (max-width: 992px) {
  .pp-rw {
    margin-top: 1em !important;
  }

  .principle-dd {
    width: 40% !important;
  }
}

@media only screen and (max-width: 575px) {
  .col-4 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top: 10px !important;
  }

  .col-3 {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }

  .principle-dd {
    width: 100% !important;
  }
}

.review-box:focus~label {
  color: #274ba7;
  top: -0.6rem;
  left: 0.6rem;
  transition: all 0.125s ease;
  background: #fff;
  padding: 0px;
}

.review-box:focus {
  border: 2px solid #274ba7;
}

.review-box:focus-visible {
  border: 2px solid #274ba7;
}

.action-desc:focus~label {
  color: #313131 !important;
  top: 0 !important;
  left: 0 !important;
  background: #fff !important;
  padding: 0 4px !important;
  font-size: 1rem !important;
  font-family: "BarlowM", sans-serif !important;
  margin: -9px 1rem !important;
}

.comment-lable {
  color: #313131 !important;
  top: 0 !important;
  left: 0 !important;
  background: #fff;
  padding: 0 4px !important;
  font-size: 1rem !important;
  font-family: "BarlowM", sans-serif !important;
}

.styled-input {
  position: relative;
}

.styled-input label {
  padding: 1rem 4px;
  position: absolute;
  top: 1rem;
  font-size: 1.1666666666666667rem;
  font-family: "BarlowM", sans-serif;
  left: 0;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  pointer-events: none;
}

.styled-input.wide {
  width: 100%;
}



.review-box:focus~span {
  width: 100%;
  -webkit-transition: all 0.075s ease;
  transition: all 0.075s ease;
}

.review-box {
  border-radius: 6px;
  height: 5em;
  resize: none;
  padding: 1rem 1rem;
  border: 0;
  width: 100%;
  font-size: 1rem;
}

.review-com {
  margin-top: 10px;
}

.add-action {
  text-align: right;
}

.action-item-con {
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
}

.add-action-btn {
  background: #274ba7;
  border-color: #274ba7;
}

.add-action-btn:hover {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action-btn:active {
  border-color: #6e92ec !important;
  color: #fff !important;
  background: #6e92ec 0% 0% no-repeat padding-box !important;
}

.add-action:active:focus {
  box-shadow: 0 0 0 0 rgb(108 117 125 / 50%) !important;
}

.act-tal-con {
  margin-top: 1em;
}

.back-btn-con {
  text-align: right;
}
</style>